<template>
    <footer class="footer">
        <div class="section bg--secondary">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="h1 mb-25">BarnehageHMS</h2>
                        <p class="h3">Verktøy for bedre jobbhelse</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
