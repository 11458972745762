<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div class="section section--form">
                    <form
                        id="create-user"
                        action="/"
                        @submit.prevent="onSubmit"
                    >
                        <fieldset>
                            <legend>
                                <h2>Opprett adminbruker</h2>
                                <p class="sm">
                                    Registrer personinfo og opprett bruker
                                    dersom du er leder i en barnehage.
                                </p>
                            </legend>
                            <FormGroup
                                v-bind="fields.name"
                                v-model:value="fields.name.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.email"
                                v-model:value="fields.email.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.password"
                                v-model:value="fields.password.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.re_password"
                                v-model:value="fields.re_password.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                        </fieldset>
                    </form>
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow">
                        <router-link
                            :to="{ name: routeNames.registration.barnehage }"
                        >
                            Tilbake
                        </router-link>
                    </div>
                    <div>
                        <button
                            type="submit"
                            form="create-user"
                            class="btn btn--primary btn--lg"
                            @click.prevent="onNextClick"
                        >
                            Neste
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {mapState, mapActions, useStore} from 'vuex';
    import {
        inputsToObject,
        mapErrorsToInputs
    } from '../../../../scripts/utils';
    import validate from '../../../../scripts/validate';
    import regProps from '../../../../constants/regProps';
    import routeNames from '@/router/routeNames';
    import {computed, onBeforeMount, onMounted, ref} from "vue";
    import {useRoute, useRouter} from "vue-router";
    import {useRoutes} from "@/composables/useRoutes";
    import {useValidation} from "@/composables/useValidation";

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const routes = useRoutes()

    const regPropsData = computed(() => store.state.regProps);
    const registrationErrors = computed(() => store.state.regErrors);



    const fields = ref({
      name: {
        type: 'text',
            name: regProps.name,
            value: '',
            label: 'Navn *',
            error: '',
            autocomplete: 'name',
            maxlength: 200
      },
      email: {
        type: 'email',
            name: regProps.email,
            value: '',
            label: 'E-post *',
            placeholder: 'E-post',
            autocomplete: 'email',
            maxlength: 254,
            id: 'email',
            error: ''
      },
      password: {
        type: 'password',
            name: regProps.password,
            value: '',
            label: 'Nytt passord *',
            error: '',
            maxlength: 255,
            id: 'new-password',
            autocomplete: 'new-password'
      },
      re_password: {
        type: 'password',
            name: 're_password',
            value: '',
            label: 'Bekreft passord *',
            error: '',
            autocomplete: 'new-password'
      }
    })

  const onNextClick = async () => {
    if (isFormValid()) {
      let regProps = inputsToObject(fields.value, [
        fields.value.re_password.name
      ]);

      try {
        await store.dispatch('setRegProps', regProps);
        await store.dispatch('clearRegErrors')
        await router.push({
          name: routes.routes.registration.confirm
        });
      } catch (error) {
        console.error('Failed to set registration properties:', error);
      }
    }
  }

  const validateField = (field) => {
    let error = null;
    switch (field.name) {
      case fields.value.name.name:
        if (!validate.stringLength(field.value)) {
          error = 'Navn må fylles inn';
        }
        break;
      case fields.value.email.name:
        if (!validate.emailIsValid(field.value)) {
          error = 'Ugyldig e-post';
        }
        if (!validate.stringLength(field.value)) {
          error = 'E-post må fylles inn';
        }
        break;
      case fields.value.password.name:
      case fields.value.re_password.name:
        error = validate.validatePasswords(
            field,
            fields.value.password,
            fields.value.re_password
        );
        break;
    }

    field.error = error ? error : '';
    field.validated = error !== null;

    return error;
  }

  onBeforeMount(() => {
      //Reroute back to start if missing properties
      if (!regPropsData.value[regProps.barnehagenavn] || !regPropsData.value[regProps.organisasjonsnummer]) {
          router.push({ name: routes.routes.registration.barnehage });
      }
  })

  onMounted(() => {
      if (registrationErrors.value) {
          mapErrorsToInputs(fields.value, registrationErrors.value);
      }
  })


  const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields, validateField);


</script>
