import { computed } from 'vue';
import { useStore } from 'vuex';
import {
    isLoginChecked,
    isLoggedIn,
    isUser,
    isOrgAdmin,
    isSysAdmin
} from '../scripts/auth';

export function useAuth() {
    const store = useStore();

    const loggedInStatus = computed(() => store.state.loggedInStatus);

    return {
        loggedInStatus,
        isLoginChecked,
        isLoggedIn,
        isUser,
        isOrgAdmin,
        isSysAdmin
    };
}
