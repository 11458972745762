<template>
    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
        <RegisterUsersForm />
    </div>
</template>

<script>
    import RegisterUsersForm from '../../components/shared/RegisterUsersForm';
    export default {
        components: {
            RegisterUsersForm
        }
    };
</script>
