<template>
    <div class="page kalender">
        <div class="section d-flex flex-grow">
            <div class="container d-flex">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    export default {
        name: 'Kalender',
        methods: {},
        beforeMount() {
            api.getTemaer();
        }
    };
</script>
