<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                        <form
                            action="/"
                            @submit.prevent="onSubmit"
                            v-if="!submitted"
                        >
                            <fieldset>
                                <legend>
                                    <h1 class="h2">{{ title }}</h1>
                                    <p class="sm">
                                        Fyll inn epostadressen din så sender vi
                                        en lenke for å sette et nytt passord.
                                    </p>
                                </legend>

                                <FormGroup
                                    v-bind="fields.username"
                                    v-model:value="fields.username.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                    :autofocus="true"
                                />
                            </fieldset>

                            <button
                                type="submit"
                                class="btn btn--primary btn--lg btn--block btn--form"
                            >
                                {{ btnTitle }}
                            </button>
                        </form>
                        <div v-if="submitted">
                            <h1 class="h2">Sjekk eposten din</h1>
                            <p>
                                Vi har sendt deg en lenke du kan bruke for å
                                tilbakestille passordet ditt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import validate from '../scripts/validate';
    import api from '../scripts/api';
    import {onBeforeMount, ref} from "vue";
    import {onBeforeRouteUpdate} from "vue-router";
    import {useValidation} from "@/composables/useValidation";


    const props = defineProps({
      title: {
        type: String,
        default: 'Glemt passord?'
      },
      btnTitle: {
        type: String,
        default: 'Send lenke på epost'
      },
      username: String
    })

    const submitted = ref(false)
    const fields = ref({
      username: {
        type: 'email',
        name: 'username',
        value: '',
        hideLabel: true,
        label: 'E-post *',
        placeholder: 'E-post',
        id: 'username',
        autocomplete: 'username',
        error: '',
        maxlength: 254
      }
    })


    const onSubmit = () => {
      if (isFormValid()) {
        api.glemtPassord(fields.value.username.value)
            .then(() => {
              submitted.value = true;
            })
            .catch(() => {});
      }
    }

    const validateField = (field) => {
      let error = null;

      if (field.name === fields.value.username.name) {
        if (!validate.emailIsValid(field.value)) {
          error = 'Ugyldig e-post';
        }
        if (!validate.stringLength(field.value)) {
          error = 'E-post må fylles inn';
        }
      }

      field.error = error || '';
      field.validated = error ? true : false;

      return error;
    }

    const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields.value, validateField);


    onBeforeRouteUpdate(() => {
      if (props.username){
        fields.value.username.value = props.username
        isFormValid()
      }
    })
</script>
