<template>
    <article class="page">
        <ArticleHeader>
            <template v-slot:firstCol>
                <h1>{{ tema.tittel }}</h1>
                <p v-if="tema.ingress" class="lead pre">{{ tema.ingress }}</p>
            </template>
            <template v-slot:secondCol v-if="tema.bilde">
                <ImageFile
                    :id="tema.bilde"
                    :alt="'Illustrasjon ' + tema.tittel"
                />
            </template>
        </ArticleHeader>
        <section class="section section--lg flex-grow">
            <div class="container">
                <div class="d-flex justify-content-end">
                    <div class="d-none d-lg-block">
                        <a href="javascript:window.print();">Skriv ut</a>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="col-lg-6 offset-lg-3"
                        v-if="
                            tema.innholdsblokker &&
                                !!tema.innholdsblokker.length
                        "
                    >
                        <div
                            v-for="innholdsblokk in tema.innholdsblokker"
                            :key="innholdsblokk.tittel"
                        >
                            <h2>{{ innholdsblokk.tittel }}</h2>
                            <p>{{ innholdsblokk.brodtekst }}</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-6 offset-lg-3"
                        v-if="
                            tema.diskusjonspunkter &&
                                !!tema.diskusjonspunkter.length
                        "
                    >
                        <div class="bg section section--bullets">
                            <h2>Diskusjonspunkter</h2>
                            <ul class="mb-0 list--square">
                                <li
                                    v-for="(diskusjonspunkt,
                                    i) in tema.diskusjonspunkter"
                                    :key="diskusjonspunkt.text"
                                    :class="
                                        i < tema.diskusjonspunkter.length - 1
                                            ? 'mb-10'
                                            : null
                                    "
                                >
                                    {{ diskusjonspunkt.tekst }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        class="col-lg-6 offset-lg-3"
                        v-if="tema.lenker && !!tema.lenker.length"
                    >
                        <div class="section--link-list">
                            <h2>Verktøy og lenker</h2>
                            <ul class="list--unstyled">
                                <li
                                    v-for="link in tema.lenker"
                                    :key="link.lenketekst"
                                    class="mb-20"
                                >
                                    <a
                                        :href="link.url"
                                        class="link--arrow-right"
                                    >
                                        <span
                                            class="icon icon--arrow-right"
                                        ></span>
                                        <span
                                            class="link__text"
                                            v-text="link.lenketekst"
                                        ></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script>
    import api from '../../scripts/api';
    import ImageFile from '../../components/shared/ImageFile';
    import ArticleHeader from '../../components/Framework/ArticleHeader';

    export default {
        components: {
            ArticleHeader,
            ImageFile
        },
        props: {
            id: String
        },
        data() {
            return {
                tema: {
                    tittel: '',
                    ingress: '',
                    bilde: '',
                    innholdsblokker: [],
                    diskusjonspunkter: [],
                    lenker: []
                }
            };
        },
        beforeMount() {
            api.getTema(this.id).then(res => {
                this.tema = Object.assign(this.tema, res);
            });
        }
    };
</script>
