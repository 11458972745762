<template>
    <div v-if="bilde">
        <img :src="bilde" :alt="alt" />
    </div>
</template>

<script>
    import api from '../../scripts/api';

    export default {
        props: {
            id: String,
            alt: String
        },
        watch: {
            id: {
                handler: function(id) {
                    if (id) {
                        this.bilde = api.getBildeUrl(id);
                    }
                },
                immediate: true
            }
        },
        data() {
            return {
                bilde: null
            };
        }
    };
</script>
