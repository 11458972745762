<template>
    <article class="page">
        <article-header :section-type-class="'section'" bg-class="">
            <template v-slot:firstCol>
                <h1>BarnehageHMS - et verktøy for bedre jobbhelse</h1>
                <p class="no-pre">
                    BarnehageHMS er et verktøy for barnehager som ønsker å jobbe
                    jevnlig og systematisk med arbeidsmiljø, HMS og
                    helsefremming. BarnehageHMS gir barnehager mulighet for å
                    diskutere hvilke utfordringer og styrker de har i
                    arbeidsmiljøet i barnehagehverdagen, og videre velge blant
                    15 aktuelle arbeidsmiljøtema. Tekstene er korte og bygger på
                    forskning, gir tips og råd, har diskusjonspunkt og utvalgte
                    lenker til hjelp i arbeidet.
                </p>
                <p class="no-pre">
                    Når styrer, verneombud og tillitsvalgte jobber sammen om
                    arbeidsmiljø og involverer resten av personalet, oppnås best
                    resultat av innsatsen.
                </p>
            </template>
            <template v-slot:secondCol>
                <img
                    src="../assets/images/illustration_banner_start.svg"
                    alt=""
                    class="w-100"
                />
            </template>
        </article-header>

        <div class="section--lg flex-grow">
            <div class="container bg--pink--light pb-40 pt-40 px-70">
                <div class="row">
                    <div class="col-md-6">
                        <div class="article-header__first-col">
                            <h2 class="mb-20">
                                Arbeidsmiljø handler om arbeidet
                            </h2>
                            <p class="no-pre">
                                Arbeidsmiljø handler om arbeidet og påvirker
                                både helse, engasjement, nærvær og kvaliteten i
                                barnehagene. Noen arbeidsmiljøfaktorer går igjen
                                i alle barnehager, men mye er også forskjellig.
                                Her får barnehagene tilgang til kunnskap om de
                                mest relevante faktorene som påvirker
                                arbeidsmiljø, arbeidshelse, engasjement og
                                kvalitet i barnehagen.
                            </p>
                            <p class="no-pre">
                                Det er viktig at hele personalet er involvert,
                                men det er leder, tillitsvalgt og verneombud
                                (HMS-gruppa) som har et hovedansvar i
                                BarnehageHMS.
                            </p>
                            <p class="no-pre">
                                IA-bransjeprogram barnehage står bak dette
                                verktøyet.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex flex-column align-items-center">
                        <img
                            src="../assets/images/illustration_block_start.svg"
                            alt=""
                            class="w-100"
                        />
                        <router-link
                            v-if="getStartedTema"
                            :to="{
                                name: routeNames.tema,
                                params: {
                                    id: getStartedTema.id,
                                },
                            }"
                            class="pink ms-5"
                        >
                            Les mer om hvordan komme i gang
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div id="temaer" class="section flex-grow bg--light">
            <div class="container">
                <h1 class="mb-20">HMS-tema</h1>
                <p class="no-pre">
                    Under finnes nyttig informasjon, tips og inspirasjon knyttet
                    til en rekke tema.
                </p>
                <p class="no-pre">
                    Flere barnehager er usikre på hvordan en komme i gang med
                    arbeidsmiljøarbeidet, da kan dere få hjelp ved å starte med
                    temaet «Hvordan komme i gang». Ellers ligger temaene i
                    tilfeldig rekkefølge – det er dere som bestemmer hvor dere
                    starter og hvilke tema dere går videre med.
                </p>
                <p class="no-pre pb-sm-4">
                    Det viktigste dere gjør nå, er å sette av tid til arbeidet.
                    Lykke til!
                </p>
                <ul v-if="temaer.length" class="row list--unstyled">
                    <li class="col-md-4" v-for="tema in temaer" :key="tema.id">
                        <TemaListItem
                            :id="tema.id"
                            :tittel="tema.tittel"
                            :bilde="tema.bilde"
                        />
                    </li>
                </ul>
            </div>
        </div>

        <section class="section section--lg flex-grow responsive-layout">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 d-flex justify-content-center">
                        <img src="../assets/images/ia-logo.png" alt="" />
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div>
                            <h3 class="mb-5">
                                IA-bransjeprogram barnehage står bak dette
                                verktøyet som kan brukes av alle barnehager:
                            </h3>
                            <p class="no-pre">
                                LO, Unio, YS, KS, Virke, NHO, Spekter,
                                Fagforbundet, Utdanningsforbundet, Delta,
                                Norlandia, FUS, PBL, KA, NAV og Arbeidstilsynet
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script>
    import ArticleHeader from '../components/Framework/ArticleHeader';
    import { useAuth } from '@/composables/useAuth';
    import TemaListItem from '@/components/List/TemaListItem.vue';
    import { onMounted, ref } from 'vue';
    import routeNames from '@/router/routeNames';
    import api from '@/scripts/api';

    export default {
        setup() {
            const { loggedInStatus, isLoggedIn, isOrgAdmin, isSysAdmin } =
                useAuth();
            const temaer = ref([]);
            const getStartedTema = ref(null);

            const fetchTemaer = async () => {
                try {
                    const response = await api.getTemaer();

                    //Id of the 'How to get started' theme. This will be different from production/dev/test etc so we require multiple ids
                    const getStartedIds = new Set([
                        'fb978a6b-9ca2-4edf-bc0f-9a76751c3636',
                        'id2',
                    ]);

                    // Clear existing data
                    temaer.value = [];
                    getStartedTema.value = null;

                    response.forEach((subject) => {
                        if (getStartedIds.has(subject.id)) {
                            getStartedTema.value = subject;
                        } else {
                            temaer.value.push(subject);
                        }
                    });
                } catch (error) {
                    console.error('Failed to fetch temaer:', error);
                }
            };
            onMounted(fetchTemaer);

            return {
                loggedInStatus,
                isLoggedIn,
                isOrgAdmin,
                isSysAdmin,
                routeNames,
                getStartedTema,
                temaer,
            };
        },
        components: {
            TemaListItem,
            'article-header': ArticleHeader,
        },
    };
</script>
