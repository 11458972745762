<template>
    <div class="calendar-mode">
        <a
            :href="$route.name === routes.kalenderMonth ? null : '#'"
            @click.prevent="onMonthClick"
            :disabled="$route.name === routes.kalenderMonth"
        >
            Måned
        </a>
        <a
            :href="$route.name === routes.kalenderHalvaar ? null : '#'"
            @click.prevent="onHalvaarClick"
            :disabled="$route.name === routes.kalenderHalvaar"
        >
            Halvår
        </a>
    </div>
</template>
<script>
    export default {
        methods: {
            onMonthClick() {
                if (this.$route.name === this.routes.kalenderMonth) {
                    return;
                }

                this.$router.push({
                    name: this.routes.kalenderMonth,
                    params: {
                        year: this.$route.params.year,
                        month: this.$route.params.month
                    }
                });
            },
            onHalvaarClick() {
                if (this.$route.name === this.routes.kalenderHalvaar) {
                    return;
                }

                this.$router.push({
                    name: this.routes.kalenderHalvaar,
                    params: {
                        year: this.$route.params.year,
                        month: this.$route.params.month
                    }
                });
            }
        }
    };
</script>
