<template>
  <div>
    <form action="/" @submit.prevent="onSubmit">
      <fieldset>
        <legend>
          <h2>{{ title }}</h2>
        </legend>

        <FormGroup
            v-bind="fields.username"
            v-model:value="fields.username.value"
            :maxlength="fields.username.maxlength"
            @on-blur="onFieldBlur"
            @on-change="onChange"
            :autofocus="true"
        />

        <FormGroup
            v-bind="fields.password"
            v-model:value="fields.password.value"
            :maxlength="fields.password.maxlength"
            @on-blur="onFieldBlur"
            @on-change="onChange"
        />

        <FormGroup
            v-if="showTokenField"
            v-bind="fields.token"
            v-model:value="fields.token.value"
            @on-blur="onFieldBlur"
            @on-change="onChange"
        />
      </fieldset>

      <p class="form-error" v-if="loginError" aria-live="assertive">
        {{ loginError }}
      </p>

      <button
          type="submit"
          class="btn btn--primary btn--lg btn--block btn--form mb-30"
      >
        {{ btnTitle }}
      </button>

      <div class="text-center mb-40">
        <router-link
            :to="{
                        name: routeNames.forgotPassword,
                        params: {
                            username: fields.username.value
                        }
                    }"
        >
          Glemt passord?
        </router-link>
      </div>
    </form>
  </div>
</template>

<script setup>
  import {mapState, useStore} from 'vuex';
  import routeHandler from '../../router/routeHandler';
  import {login} from '../../scripts/auth';
  import validate from '../../scripts/validate';
  import routeNames from '@/router/routeNames';
  import {useValidation} from '../../composables/useValidation';
  import {computed, onBeforeMount, ref, watch} from "vue";
  import {useRoute, useRouter} from "vue-router";


  const props = defineProps({
    title: {
      type: String,
      default: 'Logg inn'
    },
    btnTitle: {
      type: String,
      default: 'Logg inn'
    },
    token: String,
    username: String,
    redirect: String
  });

  const fields = ref({
    username: {
      type: 'email',
      name: 'username',
      value: '',
      hideLabel: true,
      label: 'E-post *',
      placeholder: 'E-post',
      maxlength: 254,
      id: 'username',
      autocomplete: 'username',
      error: ''
    },
    password: {
      type: 'password',
      name: 'password',
      value: '',
      hideLabel: true,
      label: 'Passord *',
      placeholder: 'Passord',
      maxlength: 255,
      id: 'current-password',
      autocomplete: 'current-password',
      error: ''
    },
    token: {
      type: 'text',
      name: 'token',
      value: '',
      label: 'Verifiseringskode *',
      error: '',
      autocomplete: 'off',
      id: 'token'
    }
  });

  const loginError = ref(null);
  const showTokenField = ref(false);

  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const loggedInStatus = computed(() => store.state.loggedInStatus);

  watch(() => props.token, (newToken) => {
    fields.value.token.value = newToken;
  }, {immediate: true});


  const onSubmit = async () => {
    if (isFormValid()) {
      let token = fields.value.token.value
          ? fields.value.token.value.trim()
          : null;
      login(
          fields.value.username.value,
          fields.value.password.value,
          token
      )
          .then(() => {
            if (!props.redirect) {
              routeHandler.loggedInRedirectHandler(
                  route.query.redirect
              );
            } else {
              router.push({
                name: props.redirect
              });
            }
            fields.value.password.value = '';
          })
          .catch(err => {
            const errors = err.response.data.errors;
            if (errors && errors.hasOwnProperty('token')) {
              fields.value.token.error = errors.token.join(
                  ', '
              );
              showTokenField.value = true;
            }
            else if (errors && errors.hasOwnProperty('username')){
                fields.value.username.error = errors.username.join(
                    ', '
                );
            }
            else {
              fields.value.password.value = '';
              loginError.value =
                  'Kunne ikke logge deg inn. Kontroller at epost og passord er korrekt';
            }
          });
    }
  }

  const validateField = (field) => {
    let error = null;
    if (field.name === fields.value.username.name) {
      if (!validate.emailIsValid(field.value)) {
        error = 'Ugyldig e-post';
      }
      if (!validate.stringLength(field.value)) {
        error = 'E-post må fylles inn';
      }
    }

    if (field.name === fields.value.password.name) {
      if (!validate.stringLength(field.value, 1)) {
        error = 'Passord må fylles inn';
      }
    }

    field.error = error || '';
    field.validated = error ? true : false;

    return error;
  }

  const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields, validateField);

  onBeforeMount(() => {
    if (route.query.user) {
      fields.value.username.value = route.query.user;
    }

    if (props.username) {
      fields.value.username.value = props.username;
      fields.value.username.disabled = true;
    }
  });
</script>
