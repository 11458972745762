<template>
    <div class="kalender__period">
        <h1>{{ title }}</h1>
        <button @click.prevent="onPrevClick" class="btn btn--prev">
            <span class="icon icon--arrow-left"></span>
            <span class="sr-only">{{ prevBtnTitle }}</span>
        </button>
        <button @click.prevent="onNextClick" class="btn btn--next">
            <span class="icon icon--arrow-right"></span>
            <span class="sr-only">{{ nextBtnTitle }}</span>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            prevBtnTitle: String,
            nextBtnTitle: String
        },
        methods: {
            onPrevClick() {
                this.$emit('on-prev-click');
            },
            onNextClick() {
                this.$emit('on-next-click');
            }
        }
    };
</script>
