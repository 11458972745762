<template>
    <div class="dropdown">
        <div
            class="dropdown__backdrop"
            :class="isOpen ? 'show' : null"
            @click="onBackdropClick"
        ></div>

        <button
            :class="toggleClass"
            type="button"
            :id="id"
            @click.prevent="onToggleClick"
            :aria-expanded="isOpen ? 'true' : 'false'"
        >
            <slot name="toggleContent"></slot>
        </button>
        <ul
            class="dropdown-menu"
            :aria-labelledby="id"
            :style="dropdownStyle"
            @click="onBackdropClick"
        >
            <slot name="dropdownContent"></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            toggleClass: String
        },
        data() {
            return {
                id: null,
                isOpen: false
            };
        },
        computed: {
            dropdownStyle() {
                if (this.isOpen) {
                    return {
                        display: 'inline-block',
                        position: 'absolute',
                        top: '100%',
                        right: 0
                    };
                }
                return null;
            }
        },
        methods: {
            onToggleClick() {
                this.isOpen = !this.isOpen;
            },
            onBackdropClick() {
                this.isOpen = false;
            }
        },
        beforeMount() {
            this.id = `dropdown_${this._uid}`;
        }
    };
</script>
