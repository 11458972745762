const stringLength = (str, min = 1) => {
    return str != null && str.length >= min;
};

const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const matches = (str1, str2) => {
    return str1 === str2;
};

const validatePasswords = (currField, password, re_password) => {
    let error = null;

    if (!stringLength(currField.value)) {
        error =
            currField.name === password.name
                ? 'Passord må fylles inn'
                : 'Bekreft passord må fylles inn';
    } else if (!stringLength(currField.value, 4)) {
        error =
            currField.name === password.name
                ? 'Passord er for kort'
                : 'Bekreft passord er for kort';
    } else if (!matches(password.value, re_password.value)) {
        const errorMsg = 'Passordene er ikke like';

        if (currField.name === password.name) {
            if (re_password.value.length) {
                error = errorMsg;
                re_password.error = errorMsg;
                re_password.validated = true;
            }
        } else {
            error = errorMsg;
            password.error = errorMsg;
            password.validated = true;
        }
    } else {
        if (currField.name === password.name) {
            if (re_password.value.length) {
                re_password.error = '';
                re_password.validated = false;
            }
        } else {
            password.error = '';
            password.validated = false;
        }
    }

    return error;
};

export default {
    stringLength,
    emailIsValid,
    matches,
    validatePasswords
};
