<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                        <div class="section section--form">
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoginForm from '../components/shared/LoginForm';
    export default {
        components: {
            LoginForm
        }
    };
</script>
