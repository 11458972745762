<template>
    <div class="page flex-grow">
        <div class="row mb-40">
            <div class="col-md-4">
                <KalenderActions :month="month" :year="year" />
            </div>
            <div class="col-md-8">
                <KalenderPeriod
                    :title="title"
                    :prev-btn-title="'Previous halvaar'"
                    :next-btn-title="'Next halvaar'"
                    @on-prev-click="onPrevClick"
                    @on-next-click="onNextClick"
                />
            </div>
        </div>
        <KalenderMode />
        <div class="calendar-half-year flex-grow">
            <div
                v-for="(month, index) in months"
                :key="month"
                class="calendar-half-year__column"
            >
                <span class="calendar-half-year__month">{{ month }}</span>
                <div v-if="aktiviteter.length">
                    <div
                        v-for="aktivitet in aktiviteterForMonth(index)"
                        :key="aktivitet.id"
                    >
                        <AktivitetItem
                            v-bind="aktivitet"
                            :mode="orientations.vertical"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    import Orientation from '../../constants/orientation';
    import KalenderActions from '../../components/Kalender/KalenderActions';
    import KalenderMode from '../../components/Kalender/KalenderMode';
    import AktivitetItem from '../../components/Kalender/AktivitetItem';
    import KalenderPeriod from '../../components/Kalender/KalenderPeriod';
    import monthNames from '../../constants/months';

    export default {
        components: {
            AktivitetItem,
            KalenderActions,
            KalenderMode,
            KalenderPeriod
        },
        props: {
            year: {
                type: Number,
                default: new Date().getFullYear()
            },
            month: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                aktiviteter: [],
                orientations: Orientation
            };
        },
        computed: {
            title() {
                return `${this.month <= 6 ? 'VÅR' : 'HØST'} - ${this.year}`;
            },
            months() {
                let months =
                    this.month <= 6
                        ? monthNames.filter((month, index) => index < 6)
                        : monthNames.filter((month, index) => index >= 6);
                return months;
            }
        },

        methods: {
            aktiviteterForMonth(index) {
                var monthNumber = this.month <= 6 ? index + 1 : index + 1 + 6;
                return this.aktiviteter.filter(
                    akt => akt.maned === monthNumber
                );
            },
            getAktiviteter() {
                api.getAktiviteterHalvaar(
                    parseInt(this.year),
                    parseInt(this.month)
                )
                    .then(res => {
                        this.aktiviteter = res;
                    })
                    .catch(() => {
                        this.aktiviteter = [];
                    });
            },
            onPrevClick() {
                var prevMonth = this.month <= 6 ? 7 : 1;
                var prevYear = this.month <= 6 ? this.year - 1 : this.year;
                this.$router.push({
                    name: this.routes.kalenderHalvaar,
                    params: {
                        year: prevYear,
                        month: prevMonth
                    }
                });
            },
            onNextClick() {
                var nextMonth = this.month <= 6 ? 7 : 1;
                var nextYear = this.month <= 6 ? this.year : this.year + 1;
                this.$router.push({
                    name: this.routes.kalenderHalvaar,
                    params: {
                        year: nextYear,
                        month: nextMonth
                    }
                });
            }
        },
        beforeMount() {
            this.getAktiviteter();
        }
    };
</script>
