<template>
    <div class="aktivitet-item">
        <div class="row">
            <div
                class="aktivitet-item__text bg"
                :class="
                    mode === orientations.horizontal ? 'col-md-6' : 'col-12'
                "
            >
                <div class="d-flex">
                    <h2 class="h3 flex-grow">
                        <span>{{ tittel }}</span>
                    </h2>
                    <a
                        v-if="isOrgAdmin()"
                        class="btn btn--icon"
                        href="#"
                        @click.prevent="onAktivitetClick"
                    >
                        <span class="sr-only">
                            Rediger
                        </span>
                        <span class="icon icon--edit"></span>
                    </a>
                </div>

                <p
                    class="sm italic"
                    v-if="beskrivelse && mode === orientations.horizontal"
                >
                    {{ beskrivelse }}
                </p>
            </div>
            <div
                class="aktivitet-item__text bg--light"
                :class="
                    mode === orientations.horizontal ? 'col-md-6' : 'col-12'
                "
            >
                <h3>HMS-tema</h3>
                <ul
                    v-if="temaerFetched && temaerFetched.length"
                    class="list--inline"
                >
                    <li
                        v-for="(tema, i) in temaerFetched"
                        :key="'aktivitet_' + id + '_tema_' + i"
                    >
                        <TemaLink :id="tema.id" :tittel="tema.tittel" />
                    </li>
                </ul>
            </div>

            <hr />
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    import Orientation from '../../constants/orientation';
    import TemaLink from '../../components/shared/TemaLink';

    export default {
        props: {
            id: String,
            tittel: String,
            beskrivelse: String,
            temaer: Array,
            mode: {
                type: String,
                default: Orientation.horizontal
            }
        },
        components: {
            TemaLink
        },
        data() {
            return {
                temaerFetched: [],
                orientations: Orientation
            };
        },
        methods: {
            onAktivitetClick() {
                this.$router.push({
                    name: this.routes.editAktivitet,
                    params: { id: this.id }
                });
            }
        },
        mounted() {
            let temaer = [...this.temaer.slice()];
            Promise.all(temaer.map(tema => api.getTema(tema)))
                .then(res => {
                    this.temaerFetched = res;
                })
                .catch(() => {});
        }
    };
</script>
