import router from './router';
import routeHandler from './routeHandler';

router.beforeEach((to, from, next) =>
    routeHandler.beforeEachHandler(to, from, next)
);

router.afterEach(to => routeHandler.afterEachHandler(to));

export default router;
