<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
