<template>
    <div v-if="user" class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
        <div class="section section--form">
            <h1>Redigere ansatt</h1>
            <p>
                <strong>Navn:</strong>
                {{ user.navn }}
            </p>
            <p>
                <strong>Epost:</strong>
                {{ user.epost }}
            </p>
            <label for="admin" class="checkbox-group">
                <input type="checkbox" id="admin" />
                <span class="sm">&nbsp;Kan redigere</span>
            </label>
        </div>
        <ul class="list--inline">
            <li>
                <button class="btn btn--delete" @click="onDeleteUserClick">
                    Slett bruker
                </button>
            </li>
            <li>
                <button class="btn btn--primary" @click="onSaveClick">
                    Lagre endringer
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            user: Object
        },
        data() {
            return {};
        },
        methods: {
            onDeleteUserClick() {
                let confirm = window.confirm('Slette bruker?');

                if (confirm) {
                    alert('TODO: NOT IMPLEMENTED!');
                    this.$router.push({
                        name: this.routes.admin.users
                    });
                }
            },
            onSaveClick() {
                let confirm = window.confirm('Lagre endringer?');

                if (confirm) {
                    alert('TODO: NOT IMPLEMENTED');
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (!to.params || !to.params.user) {
                    next({ name: vm.routes.admin.users });
                }
            });
        }
    };
</script>
