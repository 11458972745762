<template>
  <section :class="['article-header', 'section', bgClass, sectionTypeClass]">
        <div class="container">
            <div
                class="row"
                :class="
                    reverseMobile ? 'flex-column-reverse flex-md-row' : null
                "
            >
                <div class="col-md-6">
                    <div class="article-header__first-col">
                        <slot name="firstCol" />
                    </div>
                </div>
                <div class="col-md-6">
                    <slot name="secondCol" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            sectionTypeClass: {
                type: String,
                default: ''
            },
            reverseMobile: {
                type: Boolean,
                default: true
            },
            bgClass:{
              type: String,
              default: 'bg--light'
            }
        }
    };
</script>
