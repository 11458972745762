import { getInputByName } from '../scripts/utils';

export default {
    methods: {
        onChange(event) {
            let field = getInputByName(this.fields, event.target.name);
            if (field.validated) {
                this.validateField(field);
            }
        },
        onFieldBlur(event) {
            let field = getInputByName(this.fields, event.target.name);
            this.validateField(field);
        },
        isFormValid() {
            let hasErrors = false;

            for (const field in this.fields) {
                let error = this.validateField(this.fields[field]);
                if (error && error.length) {
                    hasErrors = true;
                }
            }

            return !hasErrors;
        }
    }
};
