<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <h1 class="mb-40">Du er nå logget ut</h1>
                <div class="mb-30">
                    <router-link
                        class="btn btn--primary router-link-active--no-style"
                        :to="{ name: routeNames.start }"
                    >
                        Til startsiden
                    </router-link>
                </div>
                <div class="mb-40">
                    <router-link
                        class="btn btn--primary"
                        :to="{ name: routeNames.login }"
                    >
                        Logg inn
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { logout } from '../scripts/auth';
    import routeNames from '@/router/routeNames';

    export default {
        setup(){
            return {
                routeNames
            }
        },
        methods: {},
        beforeRouteEnter(to, from, next) {
            next(() => {
                logout().then(() => true);
            });
        }
    };
</script>
