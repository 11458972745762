<template>
    <div v-if="isOrgAdmin()" class="kalender__actions">
        <div>
            <router-link
                :to="{
                    name: routes.createAktivitet,
                    query: { y: year, m: month }
                }"
                tag="button"
                class="btn btn--primary btn--lg"
            >
                Legg til aktivitet
            </router-link>
        </div>
        <div class="d-none d-lg-block">
            <a href="javascript:window.print();">Skriv ut</a>
        </div>
    </div>
</template>

<script>
    import { useAuth } from '@/composables/useAuth';
    import {useRoutes} from "@/composables/useRoutes";

    export default {
        props: {
            year: Number,
            month: Number
        },
        setup(props){
            const {isOrgAdmin} = useAuth()
            const {routes} = useRoutes()
            return {
                isOrgAdmin,
                routes
            }
        }
    };
</script>
