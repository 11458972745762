import { createStore } from 'vuex';

import { guid } from '../scripts/utils';
import error from "@/views/Error.vue";


const store = createStore({
    state(){
        return{
            loggedInStatus: null,
            cacheKey: null,
            regProps: {},
            regErrors: {},
            temaCache: {}
        }
    },
    mutations: {
        SET_LOGGED_IN_STATUS(state, loggedInStatus) {
            state.loggedInStatus = loggedInStatus;
        },
        SET_CACHE_KEY(state, cacheKey) {
            state.cacheKey = cacheKey;
        },
        SET_REG_PROP(state, { key, value }) {
            state.regProps[key] = value;
        },
        SET_REG_ERRORS(state, errors) {
            state.regErrors = errors;
        },
        CLEAR_REG_ERRORS(state, errors) {
            state.regErrors = {}
        },
        SET_TEMA_CACHE(state, temaer) {
            state.temaCache = {};
            temaer.map(tema => {
                state.temaCache[tema.id] = tema;
            });
        },
    },
    actions: {
        setLoggedInStatus({ commit }, loggedInStatus) {
            commit('SET_LOGGED_IN_STATUS', loggedInStatus);
        },
        updateCacheKey({ commit }) {
            commit('SET_CACHE_KEY', guid());
        },
        setRegProps({ commit }, props) {
            for (const key in props) {
                commit('SET_REG_PROP', { key, value: props[key] });
            }
        },
        setRegErrors({commit}, errors){
            commit('SET_REG_ERRORS', errors)
        },
        clearRegErrors({commit}){
            commit('CLEAR_REG_ERRORS')
        },
        setTemaCache({ commit }, temaer = []) {
            commit('SET_TEMA_CACHE', temaer);
        }
    },
    modules: {}
});


export default store