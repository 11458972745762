<template>
    <div>
        <textarea
            v-if="type === 'textarea'"
            class="form-control"
            :class="inputClassName"
            :id="id"
            :name="name"
            :rows="rows"
            :value="value"
            :placeholder="placeholder"
            :aria-describedby="ariaDescribedby"
            :maxlength="maxlength"
            @input="onChange"
            @blur="onBlur"
        ></textarea>
        <input
            v-else
            class="form-control"
            :class="inputClassName"
            :id="id"
            :name="name"
            :type="type"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :autofocus="autofocus ? 'autofocus' : null"
            :aria-describedby="ariaDescribedby"
            :maxlength="maxlength"
            @input="onChange"
            @blur="onBlur"
            :disabled="disabled ? 'disabled' : null"
        />
    </div>
</template>

<script setup>
  import { computed, defineProps, defineEmits } from 'vue';

  const props = defineProps({
    value: String,
    id: String,
    type: {
      type: String,
      default: 'text'
    },
    name: String,
    rows: {
      type: Number,
      default: 7
    },
    placeholder: String,
    autocomplete: {
      type: String,
      default: 'off'
    },
    ariaDescribedby: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: String,
    maxlength: Number
  });

  const emit = defineEmits(['update:value', 'on-change', 'on-blur']);

  const inputClassName = computed(() => {
    let classes = [];
    if (props.inputClass) {
      classes.push(props.inputClass);
    }

    if (props.hasError) {
      classes.push('error');
    }

    return classes;
  });


  function onChange(event) {
    if (event.which === 13 || event.keyCode === 13) {
      // Prevent action if the key is Enter
      return;
    }
    emit('update:value', event.target.value);
    emit('on-change', event);
  }

  function onBlur(event) {
    emit('on-blur', event);
  }

</script>
