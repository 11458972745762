import { reactive, toRefs } from 'vue';
import {getInputByName} from "@/scripts/utils";
export function useValidation(fields, validateField) {
    const state = reactive({
        fields,
        errors: {}
    });


    const isFormValid = () => {
        let hasErrors = false;
        for (const key in state.fields) {
            const error = validateField(state.fields[key]);
            if (error) {
                hasErrors = true;
            }
        }
        return !hasErrors;
    };

    const onChange = (event) => {
        const field = getInputByName(state.fields, event.target.name);
        if (field && field.validated) {
            validateField(field);
        }
    };

    const onFieldBlur = (event) => {
        const field = getInputByName(state.fields, event.target.name);
        validateField(field);
    };

    return {
        ...toRefs(state),
        validateField,
        isFormValid,
        onChange,
        onFieldBlur
    };

}