<template>
  <div>
    <div class="section section--form">
      <fieldset>
        <legend>
          <h2>Registrer ansatte</h2>
          <p class="sm">
            Registrer e-posten til dine ansatte for å gi de tilgang
            til verktøyet.
          </p>
        </legend>
        <ul class="list--unstyled list--edit" v-if="users.length">
          <li
              v-for="(field, i) in users"
              :key="'user_' + i"
              class="input-group"
          >
            <FormGroup
                v-bind="field"
                v-model:value="field.value"
                @on-blur="onFieldBlur"
                @on-change="onChange"
                :input-class="'mb-10'"
            />
            <label :for="'admin_' + i" class="checkbox-group">
              <input
                  type="checkbox"
                  :id="'admin_' + i"
                  v-model="field.orgAdmin"
              />
              <span class="sm">&nbsp;Skrivetilgang</span>
            </label>
          </li>
        </ul>

        <button
            class="btn btn--primary btn--add"
            @click.prevent="onAddUserClick"
        >
          <span class="icon icon--add--white"></span>
          Legg til e-post felt
        </button>
      </fieldset>
    </div>

    <button
        class="btn btn--primary btn--block btn--lg mb-30"
        @click.prevent="onRegisterUserClick"
    >
      Send e-post til ansatte
    </button>
  </div>
</template>

<script setup>
  import validate from '../../scripts/validate';
  import api from '../../scripts/api';
  import {mapErrorsToInputs} from '../../scripts/utils';
  import {onBeforeMount, ref} from "vue";
  import {useValidation} from "@/composables/useValidation";
  import {useRouter} from "vue-router";
  import {useRoutes} from "@/composables/useRoutes";

  const router = useRouter();
  const routes = useRoutes()

  const userIndex = ref(0)
  const users = ref([])

  const fields = ref({
      newUser: {
        type: 'email',
        value: '',
        orgAdmin: false,
        name: '',
        maxlength: 254,
        label: 'E-post',
        hideLabel: true,
        placeholder: 'E-post',
        error: '',
        validated: false
      }
    })

  const onAddUserClick = () => {
    addUser()
  }

  const addUser = () => {
    let cloned = Object.assign({}, fields.value.newUser);
    cloned.name = `email_${userIndex.value}`;
    userIndex.value++
    users.value.push(cloned)
  }

  const onRegisterUserClick = () => {
    const self = this;

    if (isFormValid()) {
      window.dataLayer?.push({
        event: 'form_submitted',
        form_name: 'legg-til-brukere'
      });
      let tmp = users.value
          .filter(user => user.value.length > 0)
          .map(user => {
            return {
              id: user.name,
              epost: user.value,
              skrivetilgang: user.orgAdmin
            };
          });

      api.registerNewUsers(tmp)
          .then(async () => {
              await router.push({
                  name: routes.routes.registration.success
              })
          })
          .catch(err => {
            const errors = err.response.data.errors;

            if (errors) {
              let mapErrors = {};
              for (let key in errors) {
                mapErrors[key.split(':')[1]] = errors[key];
              }
              mapErrorsToInputs(users, mapErrors);
            }
          });
    }
  }

  const validateField = (field) => {
    if (!field.value.length) {
      field.error = '';
      field.validated = false;
      return;
    }

    if (!validate.emailIsValid(field.value)) {
      field.error = 'Ugyldig e-post';
    } else {
      field.error = '';
    }

    field.validated = true;
  }

  onBeforeMount(() => {
    for (let index = 0; index < 4; index++) {
      addUser();
    }
  })

  const {errors, isFormValid, onChange, onFieldBlur} = useValidation(users, validateField);

</script>
