<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div class="section section--form">
                    <h2>Bekreft informasjon</h2>
                    <p>
                        Verifiser at informasjonen du har registrert stemmer.
                    </p>
                    <p>
                        Du kan ikke endre organisasjonsnummer senere.
                    </p>
                    <p>
                        Bruk tilbakeknappen hvis du må endre noe.
                    </p>

                    <ul class="list--reg-fields">
                        <li>
                            <strong>Barnehage:</strong>
                            {{ regPropsData[regProps.barnehagenavn] }}
                        </li>
                        <li>
                            <strong>Organisasjonsnummer:</strong>
                            {{  regPropsData[regProps.organisasjonsnummer]  }}
                        </li>
                        <li>
                            <strong>Admin bruker:</strong>
                            {{  regPropsData[regProps.name]  }}
                        </li>
                        <li>
                            <strong>E-post:</strong>
                            {{  regPropsData[regProps.email] }}
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow">
                        <router-link
                            :to="{ name: routeNames.registration.adminBruker }"
                        >
                            Tilbake
                        </router-link>
                    </div>
                    <div>
                        <button
                            class="btn btn--primary btn--lg"
                            @click.prevent="onNextClick"
                        >
                            Bekreft
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {mapState, useStore} from 'vuex';
    import api from '../../../../scripts/api';
    import regProps from '../../../../constants/regProps';
    import routeNames from '@/router/routeNames';
    import {computed, onBeforeMount, ref} from "vue";
    import {mapErrorsToInputs} from "@/scripts/utils";
    import {useRoute, useRouter} from "vue-router";
    import {useRoutes} from "@/composables/useRoutes";

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const routes = useRoutes()
    const regPropsData = computed(() => store.state.regProps);


    const fields = ref({
      regPropNames: regProps,
      props: null
    })

    const onNextClick = async () => {
      api.registerOrganisasjon(regPropsData.value)
          .then( async () => {
            await router.push({
              name: routes.routes.registration.verifyEmail
            });
          })
          .catch( async (err) => {
              const errors = err.response.data.errors;
              await store.dispatch('setRegErrors', errors)
              await router.push({
                  name: routes.routes.registration.adminBruker});
          });
    }

</script>
