import { auth } from '../scripts/auth';
import routeNames from './routeNames';
import router from './router';
import errorCodes from '../constants/errorCodes';
import {userRoles} from "@/scripts/roles";

const beforeEachHandler = (to, from, next) => {
    // triggered on first load to determine login status and forward the request
    if (!auth.isLoginChecked()) {
        auth.refreshLoginStatus()
            .then(() => next(to))
            .catch(() => next(to));
        return;
    }

    // route requires a role
    if (to.matched.some(route => route.meta.hasOwnProperty('requiresRole'))) {
        authenticatedHandler(to, from, next);
        return;
    }

    // login status has been determined and the route
    // has no special role requirement
    next();
};

// Update page title
const afterEachHandler = to => {
    let documentTitle = to.name;

    if(to.params.title){
        documentTitle = to.params.title;
    }

    document.title = documentTitle;
};

// Navigation guard for pages that require a role
const authenticatedHandler = (to, from, next) => {
    var matched = to.matched.find(match => match.name === to.name);

    if (!matched) {
        matched = to.matched[0];
    }

    var requiredRole = matched.meta.requiresRole;

    // this route requires login
    if (!auth.isLoggedIn()) {
        next({
            name: routeNames.login,
            query: { redirect: to.fullPath },
            replace: true
        });
        return;
    }

    // this route requires a user role
    if (requiredRole === userRoles.user && auth.isUser()) {
        next();
    } else if (requiredRole === userRoles.orgAdmin && auth.isOrgAdmin()) {
        next();
    } else if (requiredRole === userRoles.sysAdmin && auth.isSysAdmin()) {
        next();
    } else {
        next({
            name: routeNames.error,
            params: { code: errorCodes.unauthorizedError },
            replace: true
        });
    }
};

// Determine what page a user should be redirected to after login
const loggedInRedirectHandler = (redirect = null) => {
    if (redirect) {
        router.push({ path: redirect }).catch(() => {});
        return;
    }

    if (auth.isSysAdmin()) {
        router.push({
            name: routeNames.editTemaer
        });
        return;
    }

    if (auth.isOrgAdmin()) {
        router.push({
            name: routeNames.kalender
        });
        return;
    }

    if (auth.isUser()) {
        router.push({
            name: routeNames.kalender
        });
        return;
    }
};

export default {
    beforeEachHandler,
    afterEachHandler,
    loggedInRedirectHandler
};
