<template>
    <div id="app">
        <Header />
        <main>
            <keep-alive>
                <router-view :key="getKey()" />
            </keep-alive>
        </main>
        <Footer />
    </div>
</template>

<script>
    import Header from './components/Framework/Header';
    import Footer from './components/Framework/Footer';
    import { mapState } from 'vuex';
    import { guid } from './scripts/utils';

    export default {
        components: {
            Header,
            Footer
        },
        name: 'App',
        computed: {
            ...mapState(['cacheKey'])
        },
        methods: {
            getKey() {
                let bust = this.cacheKey;

                if (this.$route.meta.disableCache) {
                    bust = guid();
                }

                return `${this.$route.fullPath}${bust ? `?bust=${bust}` : ''}`;
            }
        }
    };
</script>
