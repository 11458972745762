<template>
    <div class="col-12">
        <h1>Ansatte for {{ organisasjonsnavn }}</h1>
        <table class="w-100 mb-30 user-table">
            <thead>
                <tr>
                    <th>Navn</th>
                    <th>Epost</th>
                    <th><span class="sr-only">Kan redigere</span></th>
                    <th><span class="sr-only">Rediger</span></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.epost">
                    <td>{{ user.navn }}</td>
                    <td>{{ user.epost }}</td>
                    <td>
                        <label :for="user.id" class="checkbox-group">
                            <input
                                type="checkbox"
                                :id="user.id"
                                @input="onCanEditChange($event, user)"
                                v-model="user.kanRedigere"
                                :disabled="
                                    user.epost === loggedInStatus.epost
                                        ? 'disabled'
                                        : null
                                "
                            />
                            <span class="sm">&nbsp;Kan redigere</span>
                        </label>
                    </td>
                    <td>
                        <button
                            v-if="user.epost !== loggedInStatus.epost"
                            @click="onDeleteUserClick(user)"
                            class="btn btn--delete btn--small"
                        >
                            Slett
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex">
            <router-link
                :to="{ name: routeNames.admin.addUsers }"
                class="btn btn--primary btn--add"
            >
                <span class="icon icon--add--white"></span>
                Legg til brukere
            </router-link>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import api from '../../scripts/api';
    import routeNames from '@/router/routeNames';

    export default {
        setup(){
            return{
                routeNames
            }
        },
        computed: {
            ...mapState(['loggedInStatus']),
            organisasjonsnavn() {
                return this.loggedInStatus.organisasjonsnavn;
            },
            adminsCount() {
                return this.users.filter(user => user.kanRedigere).length;
            }
        },
        data() {
            return {
                users: []
            };
        },
        watch: {
            $route(to) {
                if (to.name === this.routes.admin.users) {
                    this.getUsers();
                }
            }
        },
        methods: {
            getUsers() {
                api.alleBrukereIMinOrganisasjon().then(res => {
                    this.users = res.brukere;
                });
            },
            onCanEditChange(e, user) {
                if (user.kanRedigere && this.adminsCount <= 1) {
                    alert('Minst en bruker må kunne redigere!');
                    this.getUsers();
                    return;
                }
                api.toggleUserRole(user.id).then(() => {
                    this.getUsers();
                });
            },
            onDeleteUserClick(user) {
                const confirm = window.confirm(
                    `Er du sikker på at du vil slette denne brukeren?

                        Navn:   ${user.navn}
                        Epost:  ${user.epost}

                    `
                );

                if (confirm) {
                    api.slettBruker(user.id)
                        .then(() => {
                            this.getUsers();
                        })
                        .catch(() => {
                            alert('Noe gikk galt. Kunne ikke slette brukeren.');
                        });
                }
            }
        },
        beforeMount() {
            this.getUsers();
        }
    };
</script>
