<template>
    <div class="page">
        <div class="section flex-grow">
            <div class="container">
                <h1 class="mb-70 d-md-flex justify-content-center">Statistikk</h1>
                <div class="row">
                    <div class="col-md-12 d-md-flex flex-column">
                        <div><b>Totaler, BarnehageHMS:</b></div>
                        <div>Unike orgnr: {{ statistikk.totalOrgNr }}</div>
                        <div>Antall brukere: {{ statistikk.totalBrukere }}</div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12 d-md-flex flex-column">
                        <div><b>Bruk siste 30 dager</b></div>
                        <div>Unike orgnr som har benyttet løsningen: {{ statistikk.recentlyUsedOrganisasjon}}</div>
                        <div>Unike brukere som har benyttet løsningen: {{ statistikk.recentlyUsedBrukere }}</div>
                        <div>Nye orgnr: {{statistikk.newOrganisasjoner }}</div>
                        <div>Nye brukere: {{statistikk.newBrukere}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';

    export default {
        data() {
            return {
                statistikk: {
                    totalOrgNr: 0,
                    newOrganisasjoner: 0,
                    totalBrukere: 0,
                    newBrukere: 0,
                    recentlyUsedOrganisasjon: 0,
                    recentlyUsedBrukere: 0,
                }
            };
        },
        methods: {
            getStatistikk() {
                api.getStatistikk()
                    .then(res => {
                        this.statistikk = res;
                    })
                    .catch(() => {
                        this.statistikk = {};
                    });
            }
        },
        beforeMount() {
            this.getStatistikk();
        }
    };
</script>
