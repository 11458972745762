<template>
    <header class="header">
        <div class="container">
            <nav id="nav" class="nav">
                <div class="flex-grow-1">
                    <div class="logo nav-item">
                        <router-link
                            class="router-link-active--no-style"
                            :to="{ name: routeNames.start }"
                        >
                            BarnehageHMS
                        </router-link>
                    </div>
                </div>
                <ul class="nav-list">
                    <li class="nav-item">
                        <router-link :to="{ name: routeNames.start, hash:'#temaer' }">
                            HMS-tema
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="isSysAdmin()">
                        <router-link :to="{ name: routeNames.editTemaer }">
                            Rediger HMS-tema
                        </router-link>
                    </li>
                    <li v-if="
                            isLoginChecked() &&
                                !isLoggedIn() &&
                                $route.name !== routeNames.login &&
                                $route.name !== routeNames.registration.verifyEmail
                        "
                        v-cloak>
                    </li>

                    <li class="nav-dropdown" v-if="isLoggedIn()">
                        <Dropdown :toggle-class="'btn--icon btn--icon--round'">
                            <template #toggleContent>
                                <span class="icon icon--user"></span>
                                <span class="sr-only">
                                    {{ loggedInStatus.rollenavn }}
                                </span>
                            </template>
                            <template #dropdownContent>
                                <li class="dropdown-name">
                                    {{ loggedInStatus.navn }}
                                </li>
                                <li>
                                    {{ loggedInStatus.organisasjonsnavn }}
                                </li>
                                <li v-if="isOrgAdmin() && !isSysAdmin()">
                                    <router-link :to="{
                                            name: routeNames.admin.users
                                        }"
                                                 class="no-wrap">
                                        Administrer brukere
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="btn btn--primary"
                                                 :to="{
                                            name: routeNames.logout
                                        }">
                                        Logg ut
                                    </router-link>
                                </li>
                            </template>
                        </Dropdown>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
    import Dropdown from '../shared/Dropdown';
    import { useAuth } from '@/composables/useAuth';
    import routeNames from '@/router/routeNames';

    export default {
        setup(){
            const { loggedInStatus, isLoggedIn, isOrgAdmin, isSysAdmin, isLoginChecked } = useAuth();
            return {
                loggedInStatus,
                isLoggedIn,
                isOrgAdmin,
                isSysAdmin,
                isLoginChecked,
                routeNames
            };
        },
        components: {
            Dropdown
        }
    };
</script>
