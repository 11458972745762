<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
        <div class="section section--form">
          <h2>Registrering fullført</h2>
          <p class="mb-30">
            Du har nå fullført registreringen i BarnehageHMS.
          </p>
        </div>
        <button class="btn btn--primary btn--lg btn--block"
                @click.prevent="onConfirmClick">
          Introduksjon til verktøyet
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {useRoutes} from '@/composables/useRoutes';
  import {useRouter} from "vue-router";

  export default {

    setup() {
      const {routes} = useRoutes()
      const router = useRouter();


      return {
        onConfirmClick() {
          window.dataLayer?.push({
            event: 'form_submitted',
            form_name: 'registrering-fullfoert'
          });
          router.push({
            name: routes.introduction
          });
        }
      }
    }
  }
</script>