<template>
    <div class="select-tema" :class="hasError ? 'error' : null">
        <div class="select-tema__input">
            <input
                type="checkbox"
                name="temaer"
                :value="id"
                :id="inputId"
                @change="onInputChange"
                :checked="selected ? 'checked' : null"
            />
        </div>

        <div class="select-tema__content">
            <label :for="inputId">
                <h3 class="mb-10">{{ tittel }}</h3>

                <p class="sm">{{ ingress }}</p>
            </label>

            <a href="#" @click.prevent="onClick">
                Les mer
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            id: String,
            tittel: String,
            bilde: String,
            ingress: String,
            hasError: Boolean,
            selected: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                hover: false,
                inputId: null
            };
        },
        methods: {
            onClick() {
                let routeData = this.$router.resolve({
                    name: this.routes.tema,
                    params: { id: this.id }
                });
                window.open(routeData.href, '_blank');
            },
            onInputChange(e) {
                this.$emit('on-change', e);
            }
        },
        beforeMount() {
            this.inputId = `check_${this._uid}`;
        }
    };
</script>
