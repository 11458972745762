<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <h1>{{ heading }}</h1>
                <p>Error code: {{ code }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import errorCodes from '../constants/errorCodes';

    export default {
        props: {
            code: {
                type: Number,
                default: errorCodes.serverError
            }
        },
        computed: {
            heading() {
                if (this.code === errorCodes.notfoundError) {
                    return `Denne siden finnes ikke (${this.code})`;
                }
                if (this.code === errorCodes.unauthorizedError) {
                    return `Du har ikke tilgang (${this.code})`;
                }

                return `Intern server feil (${this.code})`;
            }
        }
    };
</script>
