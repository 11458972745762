<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <RegisterUsersForm />
                <div class="text-center mb-40">
                    <router-link :to="{ name: routeNames.registration.success }">
                        Hopp over dette steget
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RegisterUsersForm from '../../../../components/shared/RegisterUsersForm';
    import routeNames from '@/router/routeNames';


    export default {
        setup(){
            return{
                routeNames
            }
        },
        components: {
            RegisterUsersForm
        }
    };
</script>
