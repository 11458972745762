<template>
    <label :class="hideLabel ? 'sr-only' : null" :for="forId">
        {{ label }}
    </label>
</template>

<script>
    export default {
        props: {
            forId: String,
            label: String,
            hideLabel: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
