<template>
  <router-link
      :to="{
            name: routeNames.tema,
            params: {
                id: id
            }
        }"
  >
    {{ tittel }}
  </router-link>
</template>

<script>
  import routeNames from '@/router/routeNames';

  export default {
    setup() {
      return {routeNames}
    },

    props: {
      id: String,
      tittel: String
    }
  };
</script>
