export default {
    '--primary': '#2D7C81',
    '--secondary': '#194765',
    '--tertiary': '#CDE2B8',
    '--tertiary--light': '#E5F0DA',
    '--gray': '#CFD8DB',
    '--gray--light': '#F5F5F5',
    '--black': '#000000',
    '--white': '#ffffff',
    '--pink--light': '#EACEDD',
    '--pink': '#B51D7D',
};
