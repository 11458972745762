<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 offset-md-4">
                        <div class="section section--form">
                            <form action="/" @submit.prevent="onSubmit">
                                <fieldset>
                                    <legend>
                                        <h2>Opprett bruker</h2>
                                    </legend>
                                    <FormGroup
                                        v-bind="fields.name"
                                        :value.sync="fields.name.value"
                                        @on-blur="onFieldBlur"
                                        @on-change="onChange"
                                    />
                                    <FormGroup
                                        v-bind="fields.email"
                                        :value.sync="fields.email.value"
                                        @on-blur="onFieldBlur"
                                        @on-change="onChange"
                                    />
                                    <FormGroup
                                        v-bind="fields.password"
                                        :value.sync="fields.password.value"
                                        @on-blur="onFieldBlur"
                                        @on-change="onChange"
                                    />
                                    <FormGroup
                                        v-bind="fields.re_password"
                                        :value.sync="fields.re_password.value"
                                        @on-blur="onFieldBlur"
                                        @on-change="onChange"
                                    />
                                </fieldset>
                                <p
                                    class="form-error mb-0"
                                    v-if="createUserError"
                                    aria-live="assertive"
                                >
                                    {{ createUserError }}
                                </p>
                            </form>
                        </div>

                        <button
                            class="btn btn--primary btn--lg btn--block mb-30"
                            @click.prevent="onCreateClick"
                        >
                            Opprett
                        </button>
                        <!-- <div class="text-center mb-40">
                            <router-link
                                :to="{
                                    name: routes.forgotPassword,
                                    params: {
                                        username: fields.email.value
                                    }
                                }"
                            >
                                Glemt passord?
                            </router-link>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import errorCodes from '../../constants/errorCodes';
    import validationMixin from '../../mixins/validationMixin';
    import validate from '../../scripts/validate';
    import api from '../../scripts/api';

    export default {
        mixins: [validationMixin],
        data() {
            return {
                createUserError: null,
                token: null,
                fields: {
                    name: {
                        type: 'text',
                        name: 'name',
                        value: '',
                        label: 'Navn *',
                        maxlength: 200,
                        error: '',
                        autocomplete: 'on'
                    },
                    email: {
                        type: 'email',
                        name: 'email',
                        value: '',
                        label: 'E-post *',
                        placeholder: 'E-post',
                        autocomplete: 'on',
                        maxlength: 254,
                        error: '',
                        disabled: true
                    },
                    password: {
                        type: 'password',
                        name: 'password',
                        value: '',
                        maxlength: 255,
                        label: 'Nytt passord *',
                        error: ''
                    },
                    re_password: {
                        type: 'password',
                        name: 're_password',
                        value: '',
                        label: 'Bekreft passord *',
                        error: ''
                    }
                }
            };
        },
        methods: {
            onCreateClick() {
                const self = this;

                if (this.isFormValid()) {
                    var userObj = {
                        navn: this.fields.name.value,
                        epost: this.fields.email.value,
                        token: this.token,
                        passord: this.fields.password.value
                    };

                    api.createUser(userObj)
                        .then(() => {
                            this.$router.push({
                                name: this.routes.kalender
                            });
                        })
                        .catch(() => {
                            self.createUserError =
                                'Noe gikk galt. Hvis du allerede har registrert deg kan du logge inn.';
                        });
                }
            },
            validateField(field) {
                let error = null;

                switch (field.name) {
                    case this.fields.name.name:
                        if (!validate.stringLength(field.value)) {
                            error = 'Navn må fylles inn';
                        }
                        break;
                    case this.fields.email.name:
                        if (!validate.emailIsValid(field.value)) {
                            error = 'Ugyldig e-post';
                        }
                        if (!validate.stringLength(field.value)) {
                            error = 'E-post må fylles inn';
                        }
                        break;
                    case this.fields.password.name:
                    case this.fields.re_password.name:
                        error = validate.validatePasswords(
                            field,
                            this.fields.password,
                            this.fields.re_password
                        );
                        break;
                }

                field.error = error ? error : '';
                field.validated = error !== null;

                return error;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (!(to.query.user && to.query.token)) {
                    return next({
                        name: vm.routes.error,
                        params: {
                            code: errorCodes.notfoundError
                        }
                    });
                }
                vm.fields.email.value = to.query.user || '';
                vm.token = to.query.token || '';
            });
        }
    };
</script>
