<template>
    <div>
        <div :class="sortable ? 'sortable' : null">
            <FormLabel
                :for-id="inputId"
                :label="label"
                :hide-label="hideLabel"
            />
            <div class="sortable__actions" v-if="sortable">
                <div>
                    <button
                        @click.prevent="$emit('on-move-up')"
                        class="btn btn--icon"
                        title="Flytt opp"
                    >
                        <span class="sr-only">Flytt opp</span>
                        <span
                            class="icon icon--chevron icon--chevron--up"
                        ></span>
                    </button>
                    <button
                        @click.prevent="$emit('on-move-down')"
                        class="btn btn--icon"
                        title="Flytt ned"
                    >
                        <span class="sr-only">Flytt ned</span>
                        <span
                            class="icon icon--chevron icon--chevron--down"
                        ></span>
                    </button>
                </div>
            </div>
        </div>

        <FormInput
            :id="inputId"
            :type="type"
            :name="inputName"
            :value="value"
            :inputClass="inputClass"
            :rows="rows"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :has-error="hasError"
            :aria-describedby="errorId"
            :autofocus="autofocus"
            :disabled="disabled"
            :maxlength="maxlength"
            @update:value="updateValue"
            @on-blur="onBlur"
            @on-change="onChange"
        />
        <span v-if="hasError" class="form-error" :id="errorId">
            {{ error }}
        </span>
    </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  const props = defineProps({
    label: String,
    value: String,
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: Number,
      default: 7
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    id: String,
    autocomplete: {
      type: String,
      default: 'off'
    },
    error: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    inputClass: String,
    maxlength: Number
  });
  const emit = defineEmits(['update:value', 'on-blur', 'on-change', 'on-move-up', 'on-move-down']);


  const inputId = ref(props.id ? props.id : 'input_' + Math.random().toString(36).substr(2, 9));
  const inputName = ref(props.name ? props.name : props.label.toLowerCase().replace(/[\W_]+/g, ''));


  const hasError = computed(() => !!(props.error && props.error.length));
  const errorId = computed(() => hasError.value ? `error_${inputId.value}` : null);


  function updateValue(value) {
      emit('update:value', value);
  }

  function onBlur(event) {
    emit('on-blur', event);
  }

  function onChange(event) {
    emit('on-change', event);
  }

</script>
