export const IsIE =
    window.MSInputMethodContext && document.documentMode ? true : false;

export const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const inputsToObject = (fields, exclude = []) => {
    let obj = {};
    for (let key in fields) {
        let field = fields[key];

        if (!exclude.length) {
            obj[field.name] = field.value.trim();
            continue;
        }
        exclude.forEach(name => {
            if (field.name !== name) {
                obj[field.name] = field.value.trim();
            }
        });
    }
    return obj;
};

export const mapErrorsToInputs = (fields, errors) => {
    for (let errKey in errors) {
        let error = errors[errKey];

        for (let fieldKey in fields) {
            let field = fields[fieldKey];

            if (field.name === errKey) {
                field.error = error.join(', ');
            }
        }
    }
};

export const getInputByName = (fields, name) => {
    for (let key in fields) {
        let field = fields[key];
        if (field.name === name) {
            return field;
        }
    }
};

export const daysInMonth = (y, m) => {
    return new Date(y, m, 0).getDate();
};

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
export const getWeekNumber = (y, m, d, dowOffset = 1) => {
    /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

    var date = new Date(y, m - 1, d, 0, 0, 0, 0);
    var newYear = new Date(y, 0, 1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = day >= 0 ? day : day + 7;
    var daynum =
        Math.floor(
            (date.getTime() -
                newYear.getTime() -
                (date.getTimezoneOffset() - newYear.getTimezoneOffset()) *
                    60000) /
                86400000
        ) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
            let nYear = new Date(date.getFullYear() + 1, 0, 1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
                   the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    } else {
        weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
};

export const weeksInMonth = (y, m, distinct = true) => {
    var daysInMonth = new Date(y, m, 0).getDate();
    var weeks = [];

    for (let day = 1; day <= daysInMonth; day++) {
        weeks.push(getWeekNumber(y, m, day));
    }
    if (distinct) {
        return [...new Set(weeks)];
    }

    return weeks;
};
