<template>
    <article class="page--introduction">
        <article-header>
            <template v-slot:firstCol>
                <h1>Hvorfor kan BarnehageHMS være nyttig for dere?</h1>
                <p class="lead mb-30">
                    Et sentralt element i BarnehageHMS er kalenderen, hvor dere
                    selv plasserer arbeidsmiljøtemaer som dere har valgt ut
                    fordi disse er viktige for arbeidsmiljøet i akkurat deres
                    barnehage.
                </p>
                <p class="lead mb-30">
                    Det er dere som bestemmer!
                </p>
            </template>
            <template v-slot:secondCol>
                <img
                    src="../assets/images/illustration_banner_about.svg"
                    alt=""
                />
            </template>
        </article-header>
        <section class="section section--lg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <p class="no-pre">
                            Arbeidsmiljø handler om de ansatte, men vi ser at
                            det er en klar sammenheng mellom barnehagehverdagen
                            og de ansattes arbeidsmiljø. Derfor er det lurt at
                            arbeidsmiljøtiltak henger sammen med det arbeidet
                            man utfører.
                        </p>
                        <p class="no-pre">
                            Hva skjer for eksempel i tilvenningsfasen i
                            august/september? Hvis det er mange nye barn og
                            voksne, kan et godt arbeidsmiljøfokus være
                            rolleklarhet og støtte fra kollegaer og leder i
                            denne tiden.
                        </p>
                        <p class="no-pre">
                            Det kan være måneder i året hvor man er mer inne, og
                            relevant arbeidsmiljøfokus da kan være lydnivå og
                            inneklima.
                        </p>
                        <p class="no-pre">
                            Kanskje det hos dere i år er ekstra viktig å jobbe
                            med kompetanse og mestring, fordi det er store
                            endringer i ansattgruppa? Eller kreves det økt
                            kompetanse fordi det har oppstått en situasjon rundt
                            noen barn, som er vanskelig å løse og som fører til
                            bekymring eller manglende mestringsfølelse?
                        </p>
                        <p class="no-pre">
                            <strong>BarnehageHMS</strong>
                            gir barnehager mulighet for å diskutere hvilke
                            utfordringer de har i arbeidsmiljøet, og videre
                            velge tema en vil jobbe med, og så plassere disse i
                            den dynamisk kalender.
                        </p>
                        <p class="no-pre">
                            <strong>BarnehageHMS</strong>
                            har 13 tema med korte tekster som bygger på
                            forskning, gir tips og råd, har diskusjonspunkt og
                            utvalgte lenker til hjelp i arbeidet.
                        </p>
                        <p class="no-pre">
                            Kalenderen er dynamisk så dere kan justere innsatsen
                            underveis i barnehageåret og ved behov.
                        </p>

                        <p class="mb-40 mb-lg-80 no-pre">
                            Dette er ikke et fullstendig HMS-system, men kan
                            gjøres til en del av det systematiske lovpålagte
                            HMS-arbeidet til barnehagen.
                        </p>

                        <router-link
                            v-if="isLoggedIn() && !isSysAdmin()"
                            :to="{ name: routeNames.kalender }"
                            class="btn btn--primary btn--lg"
                        >
                            Start planlegging
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script>
    import ArticleHeader from '../components/Framework/ArticleHeader';
    import { useAuth } from '@/composables/useAuth';
    import routeNames from '@/router/routeNames';

    export default {
        setup(){
            const { isLoggedIn, isSysAdmin } = useAuth();
            return {
                isLoggedIn,
                isSysAdmin,
                routeNames}
        },
        components: {
            'article-header': ArticleHeader
        }
    };
</script>
