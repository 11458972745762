<template>
    <div class="page">
        <div class="section bg">
            <div class="container">
                <div class="row mb-30">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <h1 class="h2">{{ heading }}</h1>
                        <div class="d-flex d-flex-wrap">
                            <div>
                                <FormLabel
                                    :for-id="'aktivitet_year'"
                                    :label="'År'"
                                />
                                <div class="custom-select">
                                    <select
                                        class="form-control"
                                        v-model="aktivitet.ar"
                                        id="aktivitet_year"
                                    >
                                        <option
                                            v-for="year in years"
                                            :value="year"
                                            :key="year"
                                        >
                                            {{ year }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <FormLabel
                                    :for-id="'aktivitet_month'"
                                    :label="'Måned'"
                                />
                                <div class="custom-select">
                                    <select
                                        class="form-control"
                                        v-model="aktivitet.maned"
                                        id="aktivitet_month"
                                    >
                                        <option
                                            v-for="(month, index) in months"
                                            :value="index + 1"
                                            :key="month"
                                        >
                                            {{ month }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <fieldset>
                            <legend>
                                <h3 class="label mb-5">Beskriv aktivitet</h3>
                                <p class="sm mb-20">
                                    En aktivitet er det dere gjør i barnehagen,
                                    og er typisk knyttet til periodens fokus i
                                    årsplanen.
                                </p>
                            </legend>
                        </fieldset>
                        <FormGroup
                            :label="'Tittel *'"
                            :value.sync="aktivitet.tittel"
                            v-bind="fields.tittel"
                            :maxlength="100"
                            @on-blur="onFieldBlur"
                            @on-change="onChange"
                        />

                        <FormGroup
                            :label="'Beskrivelse *'"
                            :value.sync="aktivitet.beskrivelse"
                            v-bind="fields.beskrivelse"
                            @on-blur="onFieldBlur"
                            @on-change="onChange"
                            :type="'textarea'"
                            :rows="3"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <h2>
                            Velg relevante HMS-tema for aktiviteten
                        </h2>
                        <p class="form-error" v-if="temaerError">
                            {{ temaerError }}
                        </p>
                        <ul
                            v-if="temaer && temaer.length"
                            class="row list--unstyled"
                        >
                            <li
                                v-for="tema in temaer"
                                :key="tema.id"
                                class="col-md-6"
                            >
                                <SelectTema
                                    v-bind="tema"
                                    :selected="
                                        aktivitet.temaer.indexOf(tema.id) !== -1
                                    "
                                    :has-error="temaerError ? true : false"
                                    @on-change="onSelectTemaChange"
                                />
                            </li>
                        </ul>
                        <div class="row justify-content-end">
                            <div class="col flex-grow-0" v-if="!createMode">
                                <button
                                    class="btn btn--delete btn--lg no-wrap"
                                    @click="onDeleteClick"
                                >
                                    Slett aktivitet
                                </button>
                            </div>
                            <div class="col flex-grow-0">
                                <button
                                    class="btn btn--primary btn--lg no-wrap"
                                    @click="onSaveClick"
                                >
                                    Lagre aktivitet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectTema from '../../components/Form/SelectTema';
    import api from '../../scripts/api';
    import Months from '../../constants/months';
    import Years from '../../constants/years';
    import { mapActions } from 'vuex';
    import validationMixin from '../../mixins/validationMixin';
    import validate from '../../scripts/validate';

    export default {
        name: 'EditAktivitet',
        mixins: [validationMixin],

        props: {
            id: String
        },
        components: {
            SelectTema
        },
        data() {
            return {
                createMode: !this.id,
                aktivitet: {
                    ar: this.$route.query.y || new Date().getFullYear(),
                    maned: this.$route.query.m || new Date().getMonth() + 1,
                    tittel: '',
                    beskrivelse: '',
                    temaer: []
                },
                fields: {
                    tittel: {
                        type: 'text',
                        name: 'tittel',
                        label: 'Tittel *',
                        error: ''
                    },
                    beskrivelse: {
                        type: 'text',
                        name: 'beskrivelse',
                        label: 'Beskrivelse *',
                        error: ''
                    }
                },
                temaerError: null,
                months: Months,
                years: Years,
                temaer: [],
                originalAktivitet: null
            };
        },
        computed: {
            heading() {
                return this.createMode
                    ? 'Opprett aktivitet'
                    : 'Rediger aktivitet';
            }
        },
        methods: {
            ...mapActions(['updateCacheKey']),
            saveOriginal() {
                this.originalAktivitet = JSON.stringify(this.aktivitet);
            },
            setAktivitet() {
                if (this.createMode) {
                    this.saveOriginal();
                } else {
                    api.getAktivitet(this.id).then(res => {
                        this.aktivitet = res;
                        this.saveOriginal();
                    });
                }
            },
            validateField(field) {
                let error = null;

                if (field.name === this.fields.tittel.name) {
                    if (!validate.stringLength(this.aktivitet.tittel)) {
                        error = 'Tittel må fylles inn';
                    }
                }

                if (field.name === this.fields.beskrivelse.name) {
                    if (!validate.stringLength(this.aktivitet.beskrivelse)) {
                        error = 'Beskrivelse må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            },
            onSaveClick() {
                var temaerHasError = this.aktivitet.temaer.length === 0;

                if (temaerHasError) {
                    this.temaerError = 'Vennligst velg et eller flere tema';
                }

                if (this.isFormValid() && !temaerHasError) {
                    if (this.createMode) {
                        window.dataLayer?.push({
                            event: 'form_submitted',
                            form_name: 'opprett-aktivitet'
                        });
                    }
                    if (this.createMode) {
                        api.postAktivitet(this.aktivitet).then(() => {
                            this.handlePostSuccess();
                        });
                    } else {
                        this.aktivitet.aktivitetId = this.id;
                        api.putAktivitet(this.aktivitet).then(() => {
                            this.handlePostSuccess();
                        });
                    }
                }
            },
            handlePostSuccess() {
                this.updateCacheKey();
                this.saveOriginal();
                this.$router.push({
                    name: this.routes.kalenderMonth,
                    params: {
                        year: this.aktivitet.ar,
                        month: this.aktivitet.maned
                    }
                });
            },
            onDeleteClick() {
                let confirm = window.confirm(
                    `Du er i ferd med å slette denne aktiviteten. \nDette valget kan ikke angres. \n\nEr du sikker på at du vil slette "${this.aktivitet.tittel}"?`
                );

                if (confirm) {
                    api.deleteAktivitet(this.id).then(() => {
                        this.updateCacheKey();
                        this.$router.push({
                            name: this.routes.kalenderMonth,
                            params: {
                                year: this.aktivitet.ar,
                                month: this.aktivitet.maned
                            }
                        });
                    });
                }
            },
            onSelectTemaChange(e) {
                const value = e.target.value;
                this.temaerError = null;

                if (e.target.checked) {
                    this.aktivitet.temaer.push(value);
                } else {
                    this.aktivitet.temaer.splice(
                        this.aktivitet.temaer.indexOf(value),
                        1
                    );
                }
            }
        },
        beforeMount() {
            this.setAktivitet();

            api.getTemaer().then(res => {
                this.temaer = res;
            });
        },
        beforeRouteLeave(to, from, next) {
            if (JSON.stringify(this.aktivitet) !== this.originalAktivitet) {
                const confirm = window.confirm(
                    `Du har gjort endringer som ikke er lagret. \nHvis du fortsetter vil du miste endringene dine. \n\nEr du sikker på at du vil fortsette?`
                );

                if (confirm) {
                    this.updateCacheKey();
                }

                next(confirm);
            } else {
                next();
            }
        }
    };
</script>
