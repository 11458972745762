<template>
    <div class="page flex-grow">
        <div class="row mb-40">
            <div class="col-md-4">
                <KalenderActions :month="month" :year="year" />
            </div>
            <div class="col-md-8">
                <KalenderPeriod
                    :title="title"
                    :prev-btn-title="'Previous month'"
                    :next-btn-title="'Next month'"
                    @on-prev-click="onPrevClick"
                    @on-next-click="onNextClick"
                />
            </div>
        </div>
        <KalenderMode />
        <div class="calendar-month flex-grow">
            <div class="calendar-month__weeks">
                <div
                    v-for="(week, index) in weeks"
                    :key="week"
                    :style="getWeekStyle(week, index)"
                    class="calendar-month__column"
                >
                    <span class="calendar-month__week">Uke {{ week }}</span>
                </div>
            </div>
            <div class="calendar-month__items">
                <ul v-if="aktiviteter.length" class="list--unstyled">
                    <li
                        class=""
                        v-for="aktivitet in aktiviteter"
                        :key="aktivitet.id"
                    >
                        <AktivitetItem v-bind="aktivitet" />
                    </li>
                </ul>
                <h3 v-else class="calendar-month__empty">
                    INGEN AKTIVITETER
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    import { weeksInMonth } from '../../scripts/utils';
    import AktivitetItem from '../../components/Kalender/AktivitetItem';
    import KalenderActions from '../../components/Kalender/KalenderActions';
    import KalenderMode from '../../components/Kalender/KalenderMode';
    import KalenderPeriod from '../../components/Kalender/KalenderPeriod';

    import Months from '../../constants/months';
    export default {
        components: {
            AktivitetItem,
            KalenderActions,
            KalenderMode,
            KalenderPeriod
        },
        props: {
            year: {
                type: Number,
                default: new Date().getFullYear()
            },
            month: {
                type: Number,
                default: new Date().getMonth() + 1
            }
        },
        data() {
            return {
                aktiviteter: []
            };
        },
        computed: {
            title() {
                return `${Months[this.month - 1]} - ${this.year}`;
            },
            daysByWeeks() {
                return weeksInMonth(this.year, this.month, false);
            },
            weeks() {
                return weeksInMonth(this.year, this.month, true);
            }
        },

        methods: {
            getNumberOfDaysInWeek(weekNumber) {
                return this.daysByWeeks.filter(week => week === weekNumber)
                    .length;
            },
            getWeekStyle(weekNumber) {
                let styles = [];
                let numberOfDays = this.getNumberOfDaysInWeek(weekNumber);
                let width = (100 / this.daysByWeeks.length) * numberOfDays;

                if (numberOfDays < 5) {
                    styles.push({ color: 'transparent' });
                }

                styles.push({ width: width + '%' });
                return styles;
            },
            getAktiviteter() {
                api.getAktiviteterMaaned(
                    parseInt(this.year),
                    parseInt(this.month)
                )
                    .then(res => {
                        this.aktiviteter = res;
                    })
                    .catch(() => {
                        this.aktiviteter = [];
                    });
            },
            onPrevClick() {
                var prevMonth = this.month === 1 ? 12 : this.month - 1;
                var prevYear = this.month === 1 ? this.year - 1 : this.year;
                this.$router.push({
                    name: this.routes.kalenderMonth,
                    params: {
                        year: prevYear,
                        month: prevMonth
                    }
                });
            },
            onNextClick() {
                var nextMonth = this.month === 12 ? 1 : this.month + 1;
                var nextYear = this.month === 12 ? this.year + 1 : this.year;
                this.$router.push({
                    name: this.routes.kalenderMonth,
                    params: {
                        year: nextYear,
                        month: nextMonth
                    }
                });
            }
        },
        beforeMount() {
            this.getAktiviteter();
        }
    };
</script>
