<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
        <div v-if="token">
          <LoginForm
              :title="'Logg inn for å verifisere epostadressen din'"
              :btn-title="'Logg inn og gå videre'"
              :username="username"
              :token="token"
              :redirect="routeNames.registration.users"
          />
        </div>
        <form v-else action="/" @submit.prevent="onSubmit">
          <div class="section section--form">
            <fieldset>
              <legend>
                <h2>Barnehage opprettet</h2>
                <p class="sm">
                  En verifiseringskode har blitt sendt til din
                  epost, skriv den inn i feltet under eller
                  følg lenken i eposten for å verifisere
                  epostadressen din.
                </p>
              </legend>
              <FormGroup
                  v-bind="fields.token"
                  v-model:value="fields.token.value"
                  @on-blur="onFieldBlur"
                  @on-change="onChange"
              />
            </fieldset>
          </div>
          <button
              class="btn btn--primary btn--lg btn--block"
              @click.prevent="onNextClick"
          >
            Neste
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validate from '../../../../scripts/validate';
  import routeNames from '@/router/routeNames';
  import {onBeforeRouteUpdate, useRoute} from 'vue-router';

  import LoginForm from '../../../../components/shared/LoginForm';
  import regProps from '../../../../constants/regProps';
  import {computed, ref} from "vue";
  import store from "@/store";
  import {useValidation} from "@/composables/useValidation";


  const regPropsData = computed(() => store.state.regProps);

  const token = ref(null)
  const username = ref(null)

  const fields = ref({
      token: {
          type: 'text',
          name: 'token',
          value: '',
          label: 'Verifiseringskode *',
          error: '',
          autocomplete: 'off',
          id: 'token'
      }
  })

  const onNextClick = async () => {
      if (isFormValid()){
          if (!username.value){
              username.value = regPropsData.value[regProps.email]
          }
          token.value = fields.value.token.value
      }
  }

  const validateField = (field) => {
      let error = null;

      if (field.name === fields.value.token.name) {
          if (!validate.stringLength(field.value)) {
              error = 'Verifiseringskode må fylles inn';
          }
      }

      field.error = error || '';
      field.validated = error ? true : false;

      return error;
  }


  const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields.value, validateField);

  onBeforeRouteUpdate(async (to, from) => {
      fields.value.token.value = to.query.token;
      username.value = to.query.username;
  })

</script>
