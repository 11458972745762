<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <form action="/" @submit.prevent="onSubmit">
                    <div class="section section--form">
                        <fieldset>
                            <legend>
                                <h2>Registrer barnehage</h2>
                                <p class="sm">
                                    Registrer barnehage dersom du er leder i en
                                    barnehage.
                                </p>
                            </legend>
                            <FormGroup
                                v-bind="fields.barnehagenavn"
                                v-model:value="fields.barnehagenavn.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />

                            <FormGroup
                                v-bind="fields.organisasjonsnummer"
                                v-model:value="fields.organisasjonsnummer.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                        </fieldset>
                    </div>
                    <button
                        class="btn btn--primary btn--lg btn--block"
                        @click.prevent="onNextClick"
                    >
                        Neste
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {
        inputsToObject,
        mapErrorsToInputs
    } from '../../../../scripts/utils';
    import api from '../../../../scripts/api';
    import validate from '../../../../scripts/validate';
    import regProps from '../../../../constants/regProps';
    import {ref} from "vue";
    import {useValidation} from "@/composables/useValidation";
    import {mapActions, useStore} from "vuex";
    import {useRouter} from "vue-router";
    import {useRoutes} from "@/composables/useRoutes";

    const store = useStore();
    const router = useRouter();
    const routes = useRoutes()


    const fields = ref({
      barnehagenavn: {
        type: 'text',
        name: regProps.barnehagenavn,
        value: '',
        label: 'Navn på barnehagen *',
        error: '',
        maxlength: 200,
        autocomplete: 'organization',
        id: 'organization'
      },
      organisasjonsnummer: {
        type: 'text',
        name: regProps.organisasjonsnummer,
        value: '',
        maxlength: 9,
        label: 'Organisasjonsnummer *',
        error: ''
      }
    })

    const onNextClick = async () => {
      if (isFormValid()) {
        let regData = inputsToObject(fields.value);

        try {
          await api.validateOrganisasjon(regData);
          await store.dispatch('setRegProps', regData);
          await router.push({
            name: routes.routes.registration.adminBruker
          });
        } catch (err) {
          const errors = err.response.data.errors;
          if (errors) {
            mapErrorsToInputs(fields.value, errors);
          }
        }
      }
    }

    const validateField = (field) => {
      let error = null;

      if (field.name === fields.value.barnehagenavn.name) {
        if (!validate.stringLength(field.value)) {
          error = 'Navn på barnehagen må fylles inn';
        }
      }

      if (field.name === fields.value.organisasjonsnummer.name) {
        if (!validate.stringLength(field.value)) {
          error = 'Organisasjonsnummer må fylles inn';
        }
      }

      field.error = error || '';
      field.validated = error ? true : false;

      return error;
    }

    const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields, validateField);


</script>
