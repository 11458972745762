import './scripts/initialize';

import { createGtm } from '@gtm-support/vue-gtm';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import FormLabel from './components/Form/FormLabel';
import FormInput from './components/Form/FormInput';
import FormGroup from './components/Form/FormGroup';

const app = createApp(App);

/* todo: Fiks GTM. Denne gjør at vi får erorr (/client/overlay.js:252:58) når vi går inn på en side raskt etter å ha besøkt selve siden
app.use(
    createGtm({
    id: 'GTM-TZ972VW',
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
    })
)
 */

app.use(router)
app.use(store)

app.component('FormLabel', FormLabel);
app.component('FormInput', FormInput);
app.component('FormGroup', FormGroup);

app.mount('#app')
