import axios from 'axios';
import router from '../router/index';
import routeNames from '../router/routeNames';
import { refreshLoginStatus } from './auth';
import errorCodes from '../constants/errorCodes';

import store from '../store';

const apiRoot = '/api/v1';

const actions = {
    loginStatus: '/account/loginStatus',
    tryLogin: '/account/tryLogin',
    logout: '/account/logout',
    validateOrganisasjon: '/account/ValiderNyBarnehage',
    registerOrganisasjon: '/account/RegistrerBarnehageKonto',
    validateNewUser: '/account/ValiderRegistrerNyBarnehagebruker',
    registerNewUsers: '/account/RegistrerNyeBarnehagebrukere',
    createUser: '/account/OpprettBrukerFraInvitasjon',
    glemtPassord: '/account/glemtPassord',
    tilbakestillPassord: '/account/tilbakestillPassord',
    alleBrukereIMinOrganisasjon: '/account/alleBrukereIMinOrganisasjon',
    endreBrukerTilMotsattBrukertype: '/account/endreBrukerTilMotsattBrukertype',
    slettBruker: '/account/slettBruker',

    getTemaer: '/tema',
    getTema: id => `/tema/${id}`,
    postTema: '/tema',
    putTema: id => `/tema/${id}`,

    getFile: id => `/fil/${id}`,
    postFile: `/fil`,

    postAktivitet: '/kalender',
    putAktivitet: '/kalender/update',
    getAktivitet: id => `/kalender/${id}`,
    deleteAktivitet: id => `/kalender/${id}`,
    getMonth: '/kalender/getMonth',
    getHalfYear: '/kalender/getHalfYear',
    statistikk: '/statistics'
};

const handleApiError = err => {
    if (
        err.response.status === errorCodes.unauthorizedError ||
        err.response.status === errorCodes.forbiddenError
    ) {
        location.reload();
    }

    if (err.response.status === errorCodes.serverError) {
        router.push({
            name: routeNames.error,
            params: { code: errorCodes.serverError }
        });
    }

    if (err.response.status === errorCodes.notfoundError) {
        router.push({
            name: routeNames.error,
            params: { code: errorCodes.notfoundError }
        });
    }
};

const get = (action, params = {}) => {
    return new Promise((resolve, reject) => {
        axios
            .get(apiRoot + action, { params })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                handleApiError(err);
                reject(err);
            });
    });
};

const post = (action, body = {}, config = {}) => {
    return new Promise((resolve, reject) => {
        axios
            .post(apiRoot + action, body, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                handleApiError(err);
                reject(err);
            });
    });
};

const put = (action, body = {}) => {
    return new Promise((resolve, reject) => {
        axios
            .put(apiRoot + action, body)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                handleApiError(err);
                reject(err);
            });
    });
};

const del = action => {
    return new Promise((resolve, reject) => {
        axios
            .delete(apiRoot + action)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                handleApiError(err);
                reject(err);
            });
    });
};

const getTemaerRemoteOrCached = () => {
    return new Promise((resolve, reject) => {
        if (
            store.state.temaCache &&
            Object.keys(store.state.temaCache).length
        ) {
            let temaer = [];
            for (let key in store.state.temaCache) {
                temaer.push(store.state.temaCache[key]);
            }
            resolve(temaer);
        } else {
            get(actions.getTemaer).then(res => {
                store
                    .dispatch('setTemaCache', res)
                    .then(() => {
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        }
    });
};

const getTemaRemoteOrCached = id => {
    return new Promise((resolve, reject) => {
        getTemaerRemoteOrCached()
            .then(() => {
                let temaCache = store.state.temaCache;
                if (
                    temaCache &&
                    Object.keys(temaCache).length &&
                    temaCache.hasOwnProperty(id)
                ) {
                    resolve(temaCache[id]);
                } else {
                    reject();
                }
            })
            .catch(err => {
                reject(err);
            });
    });
};

export default {
    // Authentisering
    loginStatus() {
        return get(actions.loginStatus);
    },
    tryLogin(username, password, token = null, persistent = false) {
        return post(actions.tryLogin, {
            username,
            password,
            token,
            persistent
        });
    },
    logout() {
        return post(actions.logout);
    },

    // Registrering
    validateOrganisasjon({ organisasjonsnummer, barnehagenavn }) {
        return post(actions.validateOrganisasjon, {
            organisasjonsnummer,
            barnehagenavn
        });
    },
    registerOrganisasjon({
        organisasjonsnummer,
        barnehagenavn,
        adminNavn,
        adminEpost,
        adminPassord
    }) {
        return post(actions.registerOrganisasjon, {
            organisasjonsnummer,
            barnehagenavn,
            adminNavn,
            adminEpost,
            adminPassord
        });
    },
    validateNewUser(user) {
        return post(actions.validateNewUser, user);
    },
    registerNewUsers(users) {
        return post(actions.registerNewUsers, { brukere: users });
    },
    createUser(user) {
        return new Promise((resolve, reject) => {
            post(actions.createUser, user)
                .then(res => {
                    return refreshLoginStatus()
                        .then(() => resolve(res))
                        .catch(() => reject());
                })
                .catch(() => reject());
        });
    },
    glemtPassord(epost) {
        return post(actions.glemtPassord, { epost });
    },
    tilbakestillPassord({ token, epost, newPassword }) {
        return post(actions.tilbakestillPassord, { token, epost, newPassword });
    },
    alleBrukereIMinOrganisasjon() {
        return get(actions.alleBrukereIMinOrganisasjon);
    },
    toggleUserRole(brukerId) {
        return post(actions.endreBrukerTilMotsattBrukertype, { brukerId });
    },
    slettBruker(brukerId) {
        return post(actions.slettBruker, { brukerId });
    },

    // Temaer
    getTemaer() {
        return getTemaerRemoteOrCached();
    },
    getTema(id) {
        return getTemaRemoteOrCached(id);
    },
    postTema(tema) {
        return new Promise((resolve, reject) => {
            post(actions.postTema, tema)
                .then(res => {
                    store.dispatch('setTemaCache', []).then(() => {
                        resolve(res);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    putTema(tema) {
        return new Promise((resolve, reject) => {
            put(actions.putTema(tema.id), tema)
                .then(res => {
                    store.dispatch('setTemaCache', []).then(() => {
                        resolve(res);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // Fil
    getFile(id) {
        return get(actions.getFile(id));
    },
    postFile(bilde, config = {}) {
        return post(actions.postFile, bilde, config);
    },
    getBildeUrl(id) {
        return apiRoot + actions.getFile(id);
    },
    // Aktiviteter
    getAktiviteterMaaned(year, month) {
        return get(actions.getMonth, { year, month });
    },
    getAktiviteterHalvaar(year, month) {
        return get(actions.getHalfYear, { year, month });
    },
    getAktivitet(id) {
        return get(actions.getAktivitet(id));
    },
    getStatistikk() {
        return get(actions.statistikk);
    },
    postAktivitet(aktivitet) {
        return post(actions.postAktivitet, aktivitet);
    },
    putAktivitet(aktivitet) {
        return put(actions.putAktivitet, aktivitet);
    },
    deleteAktivitet(id) {
        return del(actions.deleteAktivitet(id));
    }
};
