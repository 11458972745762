<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                        <form action="/" @submit.prevent="onSubmit">
                            <fieldset>
                                <legend>
                                    <h2>{{ title }}</h2>
                                </legend>

                                <FormGroup
                                    v-bind="fields.newPassword"
                                    v-model:value="fields.newPassword.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                />
                                <FormGroup
                                    v-bind="fields.re_password"
                                    v-model:value="fields.re_password.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                />
                            </fieldset>
                            <div v-if="error">
                                <p class="form-error">
                                    Kunne ikke sette nytt passord. Er det lenge
                                    siden du ba om å tilbakestille passordet kan
                                    du starte prosessen på nytt.
                                </p>
                                <div class="text-center mb-40">
                                    <router-link
                                        :to="{
                                            name: routes.forgotPassword,
                                            params: {
                                                username: user
                                            }
                                        }"
                                    >
                                        Glemt passord?
                                    </router-link>
                                </div>
                            </div>
                            <button
                                v-if="!error"
                                type="submit"
                                class="btn btn--primary btn--lg btn--block btn--form"
                            >
                                {{ btnTitle }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import validate from '../scripts/validate';
    import errorCodes from '../constants/errorCodes';
    import api from '../scripts/api';
    import { mapErrorsToInputs } from '../scripts/utils';
    import {onBeforeMount, ref} from "vue";
    import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
    import {useRoutes} from "@/composables/useRoutes";
    import {useValidation} from "@/composables/useValidation";


    const route = useRoute();
    const router = useRouter();
    const {routes} = useRoutes()


    const props = defineProps({
      title: {
        type: String,
        default: 'Tilbakestill passord'
      },
      btnTitle: {
        type: String,
        default: 'Sett nytt passord'
      }
    });

    const user = ref(null)
    const token = ref(null)
    const error = ref(false)

    const fields = ref({
      newPassword: {
        type: 'password',
        name: 'newPassword',
        value: '',
        label: 'Nytt passord *',
        error: '',
        maxlength: 255,
        id: 'new-password',
        autocomplete: 'new-password'
      },
      re_password: {
        type: 'password',
        name: 're_password',
        value: '',
        label: 'Bekreft passord *',
        error: '',
        autocomplete: 'new-password'
      }
    })



    const onSubmit = () => {
      if (isFormValid()) {
        api.tilbakestillPassord({
          token: token.value,
          epost: user.value,
          newPassword: fields.value.newPassword.value
        })
            .then(() => {
              router.push({
                name: routes.login,
                query: {
                  user: user.value
                }
              })
            })
            .catch(err => {
              const errors = err.response.data.errors;

              if (errors && Object.keys(errors).length) {
                mapErrorsToInputs(fields.value, errors);
                return;
              }
              error.value = true;
            });
      }
    }

    const validateField = (field) => {
      let error = null;

      error = validate.validatePasswords(
          field,
          fields.value.newPassword,
          fields.value.re_password
      );



      field.error = error || '';
      field.validated = error ? true : false;

      return error;
    }

    const {errors, isFormValid, onChange, onFieldBlur} = useValidation(fields.value, validateField);

    onBeforeMount(() => {
      const query = route.query;
      if (!query.user || !query.token) {
        router.push({
          name: routes.error,
          params: {
            code: errorCodes.notfoundError
          }
        });
      } else {
        user.value = query.user;
        token.value = query.token;
      }
    });
</script>
